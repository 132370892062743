<template>
  <el-menu
    class="navMenu"
    mode="horizontal"
    background-color="#2F394A"
    text-color="#868E98"
    active-text-color="#fff"
    @select="jump"
    :default-active="routeId"
  >
    <template v-for="item in menuList" :key="item.id">
      <el-sub-menu
        v-if="item.children && item.children.length"
        :index="item.id.toString()"
      >
        <template #title>
          <img
            class="svgClass"
            :src="
              require(`${item.menuIcon ? item.menuIcon : './homepage.svg'}`)
            "
          />
          <span>{{ item.menuName }}</span>
        </template>
        <el-menu-item
          v-for="subItem in item.children"
          :key="subItem.id"
          :index="subItem.urls"
        >
          {{ subItem.menuName }}
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item v-else :index="item.urls">
        <img
          class="svgClass"
          :src="require(`${item.menuIcon ? item.menuIcon : './homepage.svg'}`)"
        />
        <span>{{ item.menuName }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "navMenu",
  computed: {
    ...mapGetters(["menuList"]),
    routeId() {
      // 默认打开路由
      return this.$route.path;
    },
  },
  methods:{
    jump(index,indexPath,routeResult){
      console.log(index,indexPath,routeResult)
      if(indexPath.length>1){//二级菜单，默认跳转路由
        this.$router.push(index)
      } else if(indexPath.length === 1){//一级菜单，判断是否跳转外链
        const timestamp = new Date() //
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要加1
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        // 拼接成字符串
        const dateTimeString = `${year}${month}${day}${hours}`;
        const url = index + '&digest=' + window.btoa(this.xorEncrypt(this.$store.state.token,dateTimeString)) + '&ts='+new Date().getTime()
        window.open(url)
      }
    },
    xorEncrypt(str, key) {
      const result = []
      for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i) ^ key.charCodeAt(i % key.length)
        result.push(String.fromCharCode(charCode))
      }
      return result.join('')
    }
  }
};
</script>

<style lang="scss">
.navMenu {
  .el-sub-menu {
    .el-sub-menu__title {
      height: 50px !important;
      line-height: 50px !important;
    }
  }
}
</style>

<style scoped lang="scss">
.navMenu {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: none !important;

  .el-menu-item {
    padding-top: 4px;
    height: 50px;
    line-height: 50px;
  }

  .svgClass {
    width: 18px;
    height: 18px;
    margin: 0 5px;
  }
}
</style>
