<template>
  <div class="headerCom">
    <div style="width: 200px; height: 20px">
      <img :src="logoSrc" alt="" />
    </div>
    <navMenu />
    <div v-if="$route.path !== '/homeIndex'" class="headerCom__right">
      <svg-icon
        class="headerCom__right-img"
        className="iconlogout"
        @click="logout"
      />
      <svg-icon
        v-cloak
        class="headerCom__right-img"
        className="iconset"
        v-if="role === 1"
        @click="$router.push('/admin')"
      />

      <svg-icon
        class="headerCom__right-img headerCom__right-lastImg emailClass"
        className="email"
        @click="aboutEmail"
      />

      <svg-icon
        class="headerCom__right-img headerCom__right-lastImg"
        className="iconinfo"
        @click="aboutUs"
      />
      <about v-model="aboutFlag"></about>
    </div>
    <div v-else>
      <el-button class="loginBtn" type="text" @click="showLogin">
        <svg-icon
          class="headerCom__right-img headerCom__right-lastImg"
          className="user"
        />
        登录
      </el-button>
    </div>
  </div>
  <div style="background-color: #fff; margin: 0 -20px">
    <breadCrumb />
  </div>
  <el-dialog title="提示" v-model="emaliFlag" width="30%">
    <p>
      有任何问题，请联系我们。我们的邮箱是：
      <a class="aClass" href="mailto:omnivoice@meritco-group.com"
        >omnivoice@meritco-group.com。</a
      >
    </p>
    <template #footer>
      <el-button type="primary" @click="emaliFlag = false">确 定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import navMenu from "./menu/menu";
import breadCrumb from "./breadCrumb";
import about from "./about/about";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "headerCom",
  data() {
    return {
      logoSrc: require("./logo.png"),
      aboutFlag: false,
      emaliFlag: false,
    };
  },
  computed: {
    ...mapGetters(["role"]),
  },
  methods: {
    ...mapMutations({
      setIsLoginDialog: "SET_ISSHOWLOGINDIALOG",
    }),
    ...mapActions(["logOutAction"]),
    logout() {
      this.logOutAction();
    },
    showLogin() {
      this.setIsLoginDialog(true);
    },
    aboutUs() {
      this.aboutFlag = true;
    },
    aboutEmail() {
      this.emaliFlag = true;
    },
  },
  components: {
    about,
    navMenu,
    breadCrumb,
  },
};
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none !important;
}
.loginBtn {
  color: #ccc;
  font-size: 16px;

  &:hover {
    color: #51a6f0;
  }
}

@media screen and (max-width: 1400px) {
  .headerCom {
    width: 100% !important;
  }
}

.headerCom {
  width: 1400px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  &__right {
    &-img {
      color: #fff;
      cursor: pointer;
      margin-right: 20px;
      font-size: 18px;
    }

    &-lastImg {
      margin: 0;
    }
  }
  .emailClass {
    font-size: 24px;
    position: relative;
    top: 1px;
    margin-right: 18px;
    fill: #eee;
  }
  .aClass {
    color: rgb(81, 166, 240);
  }
}
</style>
